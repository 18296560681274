/* Header.css */

.navbar {
  background-color: #c48f8b; /* Couleur plus sombre */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  font-family: 'Georgia', serif;
  font-size: 1.8rem;
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  list-style: none;
}

.navbar-nav .nav-link {
  color: white;
  font-size: 1.2rem;
  margin-right: 15px;
  font-weight: bold;
  text-decoration: none;
}

.navbar-nav .nav-link:hover {
  color: #f8f9fa;
}

/* Pour les petits écrans, on masque la barre de navigation */
.navbar-toggler {
  display: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.navbar-toggler-icon {
  width: 30px;
  height: 30px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Cacher les liens de navigation sur les petits écrans */
@media (max-width: 768px) {
  .navbar-nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #c48f8b;
    padding: 20px;
  }

  .navbar-nav.active {
    display: flex;
  }

  .navbar-toggler {
    display: block;
  }

  .navbar-toggler-icon {
    display: block;
  }
}
