/* Conteneur principal du composant */
.scratch-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}

/* Conteneur mobile avec fond d'image */
.mobile-container {
  display: flex;
  width: 350px;
  height: 800px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 3px solid rgb(0, 0, 0);
  border-radius: 15px;
  background-size: cover;  /* Ajuste la taille de l'image pour couvrir le conteneur */
  background-position: center; /* Centre l'image de fond */
  background-repeat: no-repeat; /* Évite la répétition de l'image */
}

/* Conteneur de l'image à gratter */
.scratch-card-canvas-container {
  position: absolute;
    width: 357px;
    height: 300px;
    z-index: 2;
    margin-bottom: 16px;
    display: flex;
}

/* Style pour le canvas */
.scratch-card-canvas {
  position: absolute;
    top: 0;
    left: auto;
    opacity: 1;
}

/* Style pour le texte */
.scratch-card-text {
  font-size: 24px;
  color: #000;
  z-index: 1;
  width: 278px; /* Largeur du texte pour qu'il soit centré sous l'image */
  font-family: 'Parisienne', cursive;
}


/* ScratchCardPage.css */

.scratch-card-page {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  align-items: center;
}

.page-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
}

.settings-panel {
  border: 1px solid #ddd;
  padding: 20px;
  width: 250px;
  background-color: #f9f9f9;
}

.settings-panel h3 {
  margin-top: 0;
}

.settings-panel div {
  margin-bottom: 10px;
}

.settings-panel label {
  display: block;
  margin-bottom: 5px;
}

.settings-panel input {
  width: 100%;
}


@media only screen and (max-width: 600px) {
  .page-content{
    flex-direction: column;
  }
  .modif-carte-section{
    width: 100%;
    max-width: 100% !important;
  }
}