/* App.css */

/* Couleurs thème mariage */
body {
  background-color: #fffaf0;
  color: #333;
}

h1{
  font-size: 40px !important;
  font-weight: bold;
  font-family: Neucha, cursive !important;
  color: #d3a6a1 !important;
}

h2{
  font-size: 34px !important;
  font-weight: bold;
  font-family: Neucha, cursive !important;
  color: #8C8BC4 !important;
}

h3{
  font-size: 28px !important;
  font-weight: bold;
  font-family: Neucha, cursive !important;
  color: #d3a6a1 !important;
}

h4{
  font-size: 24px !important;
  font-weight: bold;
  font-family: Neucha, cursive !important;
  color: #8C8BC4 !important;
}

h5{
  font-size: 23px !important;
  font-family: Neucha, cursive !important;
}
h6{
  font-size: 21px !important;
  font-family: Neucha, cursive !important;
}

.contentSeo{
  max-width: 1170px;
  margin-left: auto;
}

header {
  background-color: #d3a6a1;
  color: white;
  padding: 15px 20px;
}

header nav ul li a {
  color: white;
  font-weight: bold;
}

.MuiButtonBase-root{
  border-radius: 5px !important;
  color: white !important;
  font-weight: bold !important;
}

.btnMenuAdmin{
  background-color: #b37e7b !important;
  border-radius: 5px !important;
  margin-right: 5px !important;
  cursor: pointer;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.boxAllProd{
  display: grid;
}

.product-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  width: 280px;
  text-align: center;
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card img {
  border-radius: 8px;
  max-width: 100%;
  height: auto;
}

.product-card h2 {
  font-size: 18px;
  margin: 10px 0;
  color: #d3a6a1;
}

.product-card p {
  font-size: 16px;
  color: #555;
}

.product-detail h1 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #d3a6a1;
}

.product-detail p {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.product-detail button {
  background-color: #d3a6a1;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.product-detail button:hover {
  background-color: #c48f8b;
}

.titreProd {
  font-size: 40px !important;
  font-weight: bold;
  font-family: Neucha, cursive !important;
  color: #c48f8b !important;
}

.prixProd {
  font-size: 30px !important;
  font-weight: bold;
  color: #8C8BC4 !important;
}

/* Panier */
.cart-page {
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.cart-page h2 {
  color: #d3a6a1;
  margin-bottom: 30px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.cart-item img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  margin-right: 20px;
}

.cart-item h4 {
  color: #333;
  margin: 0;
}

.cart-item p {
  color: #666;
  margin: 0;
}

/* Checkout */
.checkout-page {
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.checkout-page h2 {
  color: #d3a6a1;
  margin-bottom: 30px;
}

.checkout-page form {
  display: flex;
  flex-direction: column;
}

.checkout-page button {
  background-color: #d3a6a1;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.checkout-page button:hover {
  background-color: #c48f8b;
}


.footer{
  height: 50px;
  padding: 5px;
  background-color: #c48f8b;
  text-align: center;
  width: 100%;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .boxAllProds{
    padding: 0 !important;
  }
}

.etsy-order {
  background-color: #ffdfc8;
  color: #b55716;
  border: #e5b08a 1px solid;
  border-radius: 4px;
  font-weight: bold;
  padding: 8px;
}

.direct-order {
  background-color: #e7e6f5;
  color: #8C8BC4;
  border: #8C8BC4 1px solid;
  border-radius: 4px;
  font-weight: bold;
  padding: 8px;
}

.Toastify__toast-container{
  margin-top: 45px;
}